import styled from 'styled-components';

export const CartItemContainer = styled.div`
    width: 100%; 
    display: flex; 
    height: 80px; 
    margin-bottom: 15px; 

    img { 
        width: 30%; 
    } 

`;

export const CartItemDetails = styled.div`
    width: 70%; 
    display: flex; 
    flex-direction: column; 
    align-items: flex-start; 
    justify-content: center; 
    padding: 10px 20px; 

    span { 
        font-size: 16px; 
    }
`;


export const RemoveButton = styled.div` 
    padding-left: 12px; 
    cursor: pointer; 
` 