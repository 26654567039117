export const ORDERS_ACTION_TYPES = {
    
    SET_ORDER_ID: 'orders/SET_ORDER_ID',
    SET_ORDERS: 'orders/SET_ORDERS',

    FETCH_ORDERS_START: 'orders/FETCH_ORDERS_START',
    FETCH_ORDERS_SUCCESS: 'orders/FETCH_ORDERS_SUCCESS',
    FETCH_ORDERS_FAILED: 'orders/FETCH_ORDERS_FAILED',

   
    EMAIL_ORDERS_SUCCESS: 'orders/EMAIL_ORDERS_SUCCESS',
    EMAIL_ORDERS_FAILED: 'orders/EMAIL_ORDERS_FAILED',


    UPDATE_FIRESTORE_START: 'orders/UPDATE_FIRESTORE_START',
    UPDATE_FIRESTORE_SUCCESS: 'orders/UPDATE_FIRESTORE_SUCCESS',
    UPDATE_FIRESTORE_FAILED: 'orders/UPDATE_FIRESTORE_FAILED',
}
