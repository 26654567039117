import styled from 'styled-components';


export const ExtLinksContainer = styled.div`
    
    @media screen and (max-width: 825px) {
        height: 5%;
        width: 100%;
        margin-top: 10px;
        background-color: white;
        
    }
`