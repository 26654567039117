import styled from 'styled-components';
import { SpinnerContainer } from '../spinner/spinner.styles.jsx';

export const BaseButton = styled.button`

    min-width: 165px; 
    width: auto; 
    height: 50px; 
    letter-spacing: 0.5px; 
    line-height: 50px; 
    padding: 0 35px 0 35px; 
    font-size: 15px; 
    background-color: #7c04a4; 
    color: white; 
    text-transform: uppercase; 
    font-family: 'Open Sans'; 
    font-weight: bolder; 
    border: none; cursor: pointer; 
    display: flex; 
    justify-content: center;
    align-items: center;
    
    &:hover { 
        background-color: white; 
        color: #7c04a4; 
        border: 1px solid black; 
    } 
`;

//we can extend upon the BaseButton via the following:
export const GoogleSignInButton = styled(BaseButton)`

    background-color: #4285f4; 
    color: white; 
    
    &:hover { 
        background-color: #357ae8; 
        border: none; 
    } 
`;

export const InvertedButton = styled(BaseButton)`

    background-color: white; 
    color: #7c04a4; 
    border: 1px solid black; 
    
    &:hover { 
        background-color: #7c04a4; 
        color: white; 
        border: none; 
    } 
`;

export const ButtonSpinner = styled(SpinnerContainer)`
    width: 30px;
    height: 30px;
`